import "./App.css";

function App() {
  return (
    <div className="App">
      <h2 align="center">Hello World!👋</h2>
      <h3 className="main-title">Hi, 我是 Benzan Chen.😄</h3>
      <h3 className="main-title" style={{fontWeight:'bold'}}>关于我</h3>
      <ul className="ul-content">
        <li>Android程序员,空闲也折腾 React / Node.</li>
        <li>
          现工作于大参林医药集团医疗事业中心,带领6人Android团队进行购药、问诊、慢病服务等医疗相关app开发.
        </li>
        <li>📫联系方式: abbenyyyyyy@qq.com</li>
      </ul>
      <h3 style={{fontWeight:'bold'}}>技能点</h3>
      <div className="tag-content">
        <img
          src="https://img.shields.io/badge/Android-3DDC84?style=for-the-badge&amp;logo=android&amp;logoColor=white"
          alt="Android"
        />
        <img
          src="https://img.shields.io/badge/Kotlin-0095D5?&amp;style=for-the-badge&amp;logo=kotlin&amp;logoColor=white"
          alt="Kotlin"
        />
        <img
          src="https://img.shields.io/badge/React-20232A?style=for-the-badge&amp;logo=react&amp;logoColor=61DAFB"
          alt="React"
        />
        <img
          src="https://img.shields.io/badge/Node.js-339933?style=for-the-badge&amp;logo=nodedotjs&amp;logoColor=white"
          alt="Node"
        />
      </div>
      <div className='footer'>
        <div>技术经验总结与分享</div>
        <div>总结并分享工作开发过程中的经验与难题。</div>
        <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
          粤ICP备18033171号-1
        </a>
      </div>
    </div>
  );
}

export default App;
